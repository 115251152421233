<template>
  <header
    class="HeaderStep"
    :class="`HeaderStep-theme-${theme}`">

    <div
      class="container">
      <LogoBase
        class="__logo"
        :color="computedLogo.color"
        :name="computedLogo.name"
        :width="computedLogo.width"
        :height="computedLogo.height"
      />

      <h2
        class="__title"
        v-html="computeTitle(title)">
      </h2>

      <p
        class="__subtitle"
        v-show="subtitle"
        v-html="computeTitle(subtitle)"
        key="header-global-subtitle">
      </p>

      <ProgressStepper
        v-if="isStepperTest && stepperSteps.length"
        class="__ProgressStepper"
        key="header-global-stepper"
        :is-compact="deviceWidth < 520"
        :active-step-id="activeStepperStepId"
        :steps="stepperSteps"
      />

      <ProgressSteps
        class="__ProgressSteps"
        v-else-if="progressLabel"
        :label="computeTitle(progressLabel)"
        key="header-global-steps"
      />
    </div>

    <div
      class="__shape">
    </div>
  </header>
</template>


<script>
import LogoBase from '@/components/LogoBase.vue';
import ProgressSteps from '@/components/ProgressSteps.vue';
import ProgressStepper from '@/components/ProgressStepper.vue';

export default {
  name: 'HeaderStep',

  components: {
    LogoBase,
    ProgressSteps,
    ProgressStepper,
  },

  props: {
    logo: {
      type: Object,
      default: null,
    },
    title: {
      type: [String, Function],
      default: null,
    },
    subtitle: {
      type: [String, Function],
      default: null,
    },
    progressLabel: {
      type: [String, Function],
      default: null,
    },
    theme: {
      type: String,
      defaut: 'default',
    },
    stepperSteps: {
      type: Array,
      default: () => [],
    },
    activeStepperStepId: {
      type: String,
      default: null,
    },
  },

  inject: [
    'deviceWidth',
    'session',
  ],

  computed: {
    computedLogo() {
      return {
        color: this.logo?.color || null,
        name: this.logo?.name || 'domo',
        width: this.logo?.width || '80',
        height: this.logo?.height || '80',
      };
    },

    isStepperTest() {
      const isLangEn = !this.$route.params.lang || this.$route.params.lang === 'en';
      const isTestQuery = !!this.$route.query?.st;
      return isLangEn && isTestQuery;
    },
  },

  methods: {
    computeTitle(title) {
      if (!title) return null;
      if (typeof title === 'string') return title;
      return title(this.session);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.HeaderStep {
  position: relative;
  padding-top: 40px;
  padding-bottom: 16px;
  text-align: center;
}

.__logo {
  max-width: 100%;
}

.__title {
  margin-top: 32px;
  font-size: 36px;
  font-weight: $font-weight-normal;
}

.__subtitle {
  margin-top: 16px;
  font-size: 16px;
  font-weight: $font-weight-normal;
}

.__ProgressSteps {
  margin-top: 16px;
}

.__ProgressStepper {
  margin-bottom: 20px;
  margin-top: 32px;
}

// Desktop Styles
@include bp-iPad {
  .__title {
    font-size: 42px;
  }

  .__subtitle {
    font-size: 16px;
  }
}

// Background Shape
.__shape {
  display: none;
  position: absolute;
  z-index: -1;
  bottom: -24px;
  left: 0;
  width: 100%;
  height: 150%;
  background-color: $light-blue;
  background: radial-gradient(closest-side, #99CCEE, #6699CC);
  transform: skewY(-3deg);
}

// Theme Default
.HeaderStep-theme-blue {
  padding-bottom: 48px;
  color: $white;
  .__shape {
    display: block;
  }
}
</style>
