<template>
  <div
    class="ProgressSteps">
    <span
      class="__line">
    </span>

    <div
      class="__label">
      {{ label }}
    </div>

    <span
      class="__line">
    </span>
  </div>
</template>


<script>
export default {
  name: 'ProgressSteps',

  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ProgressSteps {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $mute;
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.__label {
  padding: 12px;
}

.__line {
  flex: 1 1 50%;
  height: 1px;
  background: $border-color;
}
</style>
