<template>
  <ul
    class="ListIcons">
    <li
      class="ListIcons__Item"
      v-for="(item, itemIndex) in items"
      :key="`list-icon-item-${itemIndex}`">
      <IconBase
        class="__icon"
        :name="iconName"
        width="24"
        height="24"
      />
      <div
        class="__text"
        v-html="item">
      </div>
    </li>
  </ul>
</template>


<script>
import IconBase from '@/components/IconBase.vue';

export default {
  name: 'ListIcons',

  components: {
    IconBase,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    iconName: {
      type: String,
      default: 'checkmark-bubble',
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ListIcons {
  list-style: none;
}
.ListIcons__Item {
  display: flex;
  margin-top: 20px;
  .__icon {
    flex: 0 0 24px;
    color: $green;
  }
  .__text {
    flex: 1 1 100%;
    padding-left: 10px;
  }
}
</style>
