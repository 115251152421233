<template>
  <div>
    <div
      class="InputText"
      :class="{
        isValue,
        isFocused,
        isDisabled,
        isInvalid,
        isPlaceholderPersistent,
      }"
      :style="inputStyle">

      <span
        class="__content-leading"
        v-if="icon || $slots.leading"
        key="input-text-content-leading">
        <slot
          name="leading">
          <IconBase
            :name="icon"
            width="16"
            height="16"
          />
        </slot>
      </span>

      <div
        class="__field">
        <input
          class="__input"
          ref="input"
          :type="computedInputType"
          :value="modelValue"
          :autofocus="autofocus"
          :disabled="isDisabled"
          @blur="handleBlur"
          @focus="handleFocus"
          @input="handleInput"
          @keyup="handleKeyup"
        />

        <span
          class="__placeholder"
          v-if="placeholder && (isPlaceholderPersistent || !isValue)"
          key="input-text-placeholder">
          {{ placeholder }}
        </span>
      </div>

      <button
        class="__icon-trailing"
        v-if="isShowingTrailingIcon"
        key="input-text-icon-trailing"
        type="button"
        @click.prevent.stop="handleTrailingIconClick">
        <IconBase
          :name="computedTrailingIcon"
          width="16"
          height="16"
        />
      </button>

    </div>

    <ErrorMessage
      class="__error"
      v-if="isInvalid && errorMessage"
      :icon="null"
      key="input-text-error-message">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>


<script>
import IconBase from '@/components/IconBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'InputText',

  components: {
    IconBase,
    ErrorMessage,
  },

  emits: [
    'blur',
    'focus',
    'keyup',
    'update:modelValue',
  ],

  data() {
    return {
      isFocused: false,
      isPasswordVisible: false,
    };
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isPlaceholderPersistent: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    inputStyle: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isValue() {
      return !!this.modelValue;
    },

    computedInputType() {
      return (this.isTypePassword && this.isPasswordVisible)
        ? 'text'
        : this.type;
    },

    isTypePassword() {
      return this.type === 'password';
    },

    computedTrailingIcon() {
      if (this.isTypePassword) {
        return this.isPasswordVisible
          ? 'hidden'
          : 'visible';
      }

      return 'x-bubble';
    },

    isShowingTrailingIcon() {
      return this.isValue
      && (this.isClearable || this.isTypePassword);
    },
  },

  methods: {
    handleInput($event) {
      this.$emit('update:modelValue', $event.target.value);
    },

    handleFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
    },

    handleBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);
    },

    handleKeyup(e) {
      this.$emit('keyup', e);
    },

    handleTrailingIconClick() {
      if (this.isClearable) {
        this.$emit('update:modelValue', '');
        this.focus();
      }

      if (this.isTypePassword) {
        this.isPasswordVisible = !this.isPasswordVisible;
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    select() {
      this.$refs.input.select();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.InputText {
  display: flex;
  position: relative;
  height: inherit;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: $radius;
}

.__content-leading {
  flex: 0 0 16px;
  margin-left: 12px;
  text-align: center;
  color: $mute;
}

.__icon-trailing {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  color: $dark;
  border-radius: 50%;
  &:hover,
  &:focus {
    background: $light;
  }
}

.__field {
  position: relative;
  flex: 1 1 100%;
  white-space: nowrap;
}

.__input {
  padding-top: 12px;
  padding-bottom: 10px;
  background: transparent;
}

.__placeholder {
  position: absolute;
  top: 9px;
  left: 12px;
  color: $mute;
  pointer-events: none;
  user-select: none;
  transform-origin: top left;
  will-change: transform;
  transition: transform 0.1s ease 0s;
}

.__error {
  margin-top: 4px;
}


// isFocused
.InputText.isFocused {
  border-color: $dark;
}


// isDisabled
.InputText.isDisabled {
  cursor: not-allowed;
}


// isPlaceholderPersistent
.isPlaceholderPersistent.isValue {
  & > .__field > .__input {
    padding-top: 14px;
    padding-bottom: 8px;
  }

  & > .__field > .__placeholder {
    top: 10px;
    transform: translate(0, -6px) scale(0.5);
  }
}

// isInvalid
.isInvalid {
  border-bottom-color: $red;
}
</style>
