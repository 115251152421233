<template>
  <div
    class="ErrorMessage">
    <IconBase
      v-if="icon"
      class="__icon"
      :name="icon"
      width="14"
      height="14"
    />

    <div
      class="__message">
      <slot/>
    </div>

  </div>
</template>


<script>
import IconBase from '@/components/IconBase.vue';

export default {
  name: 'ErrorMessage',

  components: {
    IconBase,
  },

  props: {
    icon: {
      type: String,
      default: 'important-bubble',
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ErrorMessage {
  display: flex;
  position: relative;
  color: $red;
  text-align: left;
  font-size: 12px;
  font-weight: $font-weight-normal;
  line-height: 1.3;
}

.__icon {
  flex: 0 0 14px;
  margin-right: 6px;
  color: $red;
}

.__message {
  flex: 1 1 100%;
}
</style>
