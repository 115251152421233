<template>
  <div class="LogoBanner">
    <div
      v-if="label" key="logo-banner-label"
      class="__label">
      {{ label }}
    </div>
    <div
      class="Logos">
      <div
        class="__logo-wrapper"
        v-for="logo in computedLogos"
        :style="computedLogoWrapperStyle"
        :key="logo.src">
        <a
          v-if="logo.href"
          class="__logo"
          :style="getLogoStyle(logo)"
          :href="logo.href">
        </a>

        <div
          v-else
          class="__logo"
          :style="getLogoStyle(logo)">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'LogoBanner',

  inject: [
    'deviceWidth',
  ],

  props: {
    label: {
      type: String,
      default: '',
    },
    logos: {
      type: Array, // { src, href, maxWidth }
      required: true,
    },
    breakpoints: {
      type: Array,
      default: () => [{
        width: 0, // Works Mobile-Up
        columns: 5,
        rows: 1,
      }],
    },
  },

  computed: {
    computedLogos() {
      if (
        this.activeBreakpoint
        && this.activeBreakpoint.rows
        && this.logos.length > this.activeBreakpoint.columns
      ) {
        return this.logos.slice(0, (this.activeBreakpoint.columns * this.activeBreakpoint.rows));
      }

      return this.logos;
    },

    activeBreakpoint() {
      const activeBreakpoint = [...this.breakpoints]
        .sort((a, b) => b.width - a.width)
        .find((breakpoint) => this.deviceWidth >= breakpoint.width);

      return activeBreakpoint || null;
    },

    numberOfColumns() {
      return this.activeBreakpoint
        ? this.activeBreakpoint.columns
        : this.logos.length;
    },

    computedLogoWrapperStyle() {
      return {
        width: `${100 / this.numberOfColumns}%`,
      };
    },
  },

  methods: {
    getLogoStyle(logo) {
      return {
        backgroundImage: `url('${logo.src}')`,
        maxWidth: `${logo.maxWidth || 100}px`,
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.__label {
  font-size: 14px;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  text-transform: uppercase;
}

.Logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.__logo-wrapper {
  flex: 0 1 auto;
  height: 64px;
  margin-top: 24px;
  text-align: center;
}

.__logo {
  display: inline-block;
  width: 100%;
  max-width: 100px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
