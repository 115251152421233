<template>
  <div
    class="ProgressStepper" :class="{ isCompact }">
    <div class="Line"></div>

    <div
      v-for="(step, stepIdx) in steps"
      class="Step"
      :class="{ isActive: isStepActive(step)}"
      :key="step.id">
      <div class="__number">{{ stepIdx + 1 }}</div>
      <div class="__label">{{ step.label }}</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ProgressStepper',

  props: {
    activeStepId: {
      type: String,
      default: null,
    },

    isCompact: {
      type: Boolean,
      default: false,
    },

    steps: {
      type: Array, // { id, label }
      required: true,
    },
  },

  computed: {
    activeStepIndex() {
      return this.steps.findIndex((step) => step.id === this.activeStepId) || 0;
    },
  },

  methods: {
    isStepActive(step) {
      const stepIndex = this.steps.findIndex((s) => s.id === step.id) || 0;
      return stepIndex <= this.activeStepIndex;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ProgressStepper {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  white-space: nowrap;
}

// Line
.Line {
  background: #BAC8D6;
  bottom: 0;
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 100%;
}

// Step
.Step {
  align-items: center;
  background: #FFFFFF;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  padding-right: 8px;
  z-index: 1;
  & + .Step {
    padding-left: 8px;
  }
  &:last-child {
    padding-right: 0px !important;
  }
}

.__number,
.__label {
  font-size: 16px;
  font-weight: 400;
}

.__number {
  background: #888888;
  border-radius: 50%;
  color: #FFFFFF;
  flex: 0 0 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
}

.__label {
  color: #888888;
  flex: 0 0 auto;
  padding-left: 8px;
}

// isActive
.Step.isActive {
  .__number {
    background: $light-blue;
    color: #111111;
  }
  .__label {
    color: #111111;
  }
}

// isCompact
.isCompact {
  .Line {
    display: none;
  }

  .Step {
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }

  .__number,
  .__label {
    font-size: 12px;
    font-weight: 600;
  }

  .__number {
    height: 24px;
    line-height: 24px;
    margin: 0 auto;
    width: 24px;
  }

  .__label {
    margin-top: 8px;
    padding-left: 0px !important;
  }
}
</style>
